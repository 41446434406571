<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">分红模板</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="模板名称">
					<a-input v-model="query.name" placeholder="请输入" />
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-row class="table-action-list" style="margin-top: 20px">
			<a-button type="primary" @click="add" style=" margin-left: 10px">+新增</a-button>
		</a-row>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>
			<span slot="proxy_division" slot-scope="text, record">
				<a-space>
					{{ record.proxy_division + '%' }}
				</a-space>
			</span>
			<span slot="platform_division" slot-scope="text, record">
				<a-space>
					{{ record.platform_division + '%' }}
				</a-space>
			</span>
			<span slot="hospital_division" slot-scope="text, record">
				<a-space>
					{{ record.hospital_division + '%' }}
				</a-space>
			</span>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a @click="toEdit(record)">编辑</a>
					<a-popconfirm title="确认删除该模板" ok-text="是" cancel-text="否" @confirm="del(record.show_id)">
						<a style="color: #E0352B">删除</a>
					</a-popconfirm>
				</a-space>
			</span>
		</a-table>
		<template>
			<a-modal :title="(show_id ? '编辑' : '新增') + '账户'" :visible="visible" ok-text="提交" cancel-text="取消"
				:confirm-loading="confirmLoading" @ok="submitInfo" @cancel="handleCancel">
				<a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="equipmentForm" :model="form"
					:rules="info_rules">
					<a-form-model-item prop="name" label="模板名称">
						<a-input v-model="form.name"></a-input>
					</a-form-model-item>
					<a-form-model-item prop="platform_division" label="平台分成">
						<a-input v-model="form.platform_division"></a-input>
					</a-form-model-item>
					<a-form-model-item prop="hospital_division" label="医院分成">
						<a-input v-model="form.hospital_division"></a-input>
					</a-form-model-item>
					<a-form-model-item prop="proxy_division" label="代理商分成">
						<a-input v-model="form.proxy_division"></a-input>
					</a-form-model-item>
				</a-form-model>
			</a-modal>
		</template>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'

export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10
			},
			show_id: null,
			form: {

			},
			hospitals: [],
			hospitalselsect: [],
			departments: [],
			pagetotal: 0,
			ks_visible: false,
			bindObj: {},
			visible: false,
			confirmLoading: false,
			equipment: null,
			columns: [{
				title: '序号',
				customRender: (text, record, index) => index + 1
			},
			{
				title: '模板名称',
				dataIndex: 'name'
			},
			{
				title: '平台',
				scopedSlots: {
					customRender: 'platform_division'
				}
			},
			{
				title: '医院',
				scopedSlots: {
					customRender: 'hospital_division'
				}
			},
			{
				title: '代理商',
				scopedSlots: {
					customRender: 'proxy_division'
				}
			},
			{
				title: '操作',
				scopedSlots: {
					customRender: 'action'
				}
			}
			],
			data: [],
			selectedRowKeys: [],
			info_rules: {
				name: [
					{
						required: true,
						message: '填写姓名',
						trigger: 'blur'
					}
				],
				platform_division: [
					{
						required: true,
						message: '请填写平台分成',
						trigger: 'blur'
					}
				],
				hospital_division: [
					{
						required: true,
						message: '请填写医院分成',
						trigger: 'blur'
					}
				],
				proxy_division: [
					{
						required: true,
						message: '请填写代理商分成',
						trigger: 'blur'
					}
				]
			},
		}
	},
	created() {
		this.customer_id = this.$route.query.cid
		this.getList()
	},
	methods: {
		handleCancel() {
			this.visible = false;
			this.initForm()
		},
		add() {
			this.visible = true;
		},
		toEdit(item) {
			this.form = { ...item };
			this.show_id = item.show_id;
			this.visible = true;
		},
		initForm() {
			this.form = {
			}
			this.show_id = null
		},
		submitInfo() {
			this.$refs.equipmentForm.validate(valid => {
				if (valid) {
					this.confirmLoading = true
					let url = this.show_id ? 'edit' : 'add'
					if (this.show_id) {
						this.form.show_id = this.show_id
					}
					this.$post("template/" + url, this.form)
						.then(res => {
							let { code, msg, data } = res
							this.confirmLoading = false
							if (code == 0) {
								this.$message.success(msg, 1.5)
								this.handleCancel()
								this.getList()
							} else {
								this.$message.error(msg, 1.5)
							}
						})
						.catch(err => {
							this.confirmLoading = false
						})
				}
			})
		},
		del(id) {
			this.$post(domain + '/admin/v1/template/delete', { show_id: id }).then(res => {
				if (res.code === 0) {
					this.$message.success(res.msg)
					this.getList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit,
			}
			this.getList()
		},
		getList() {
			this.$post(domain + '/admin/v1/template/list', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList();
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList();
			console.log(query)
		},
		changeCheck(value) {
			if (value.length === 1) {
				this.query.is_on = value[0]
			} else {
				this.query.is_on = null
			}
			this.getList()
		},
		onSelectChange(selectedRowKeys) {
			console.log('选中中', selectedRowKeys)
			this.selectedRowKeys = selectedRowKeys
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
